<template>
  <div class="width100 page-yellow">
    <div class="content-box margin-auto">
      <div class="content-box margin-auto flex margin-top3 margin-bottom3">
        <div>
          <img class="cursor-pointer logo-img" src="@/assets/home/logo@3x.png" alt="logo" @click="redirect('/home')"/>
        </div>
        <div class="flex1 margin-left6">
          <div class="flex flex-center search-box border-radius6 overflow-hidden background-color27">
            <div class="flex flex-center background-color26 padding-left3 padding-right3 padding-top2 padding-bottom2 fontSize14" style="margin-left: 2px; border-radius: 20px 0 0 20px;">
              <el-dropdown style="width: 80px" @command="handleChangeQueryType">
                <span class="el-dropdown-link">
                  {{ queryType === 1 ? "找货" : "找商家" }}
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :command="1" v-if="isImportTP">找货</el-dropdown-item>
                  <el-dropdown-item :command="2">找商家</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <input ref="searchInput" @keydown.enter="setKeyword" type="text" v-model="keyword" placeholder="公司名称、汽配城"
                      class="search-box-input flex1 padding-left4 padding-right4 padding-top2 padding-bottom2 fontSize14"/>
            <div @click="setKeyword" class="flex flex-center background-color27 padding-left4 padding-right4 padding-top2 padding-bottom2 fontSize16 textColor10 cursor-pointer">
              <span>搜索</span>
              <img class="margin-left1 search-img" src="@/assets/home/search.png" alt="search">
            </div>
          </div>
          <div class="fontSize12 textColor09 overflow-hidden box-sizing padding-left2 padding-right2 margin-top1 text-show-one" style="height: 36px">
            <span v-for="(item,index) in searchHistory" :key="index" @click="chooseTag(item.keyword)"
                  class="line-height3 border-radius1 padding1 padding-left2 padding-right2 margin-right2 cursor-pointer actives05 box-shadow5">
              {{ item.keyword }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="conditions-box content-box margin-auto background-color07 margin-top4 margin-bottom4 padding3 box-sizing border-radius2 textColor10">
      <div v-show="isQueryMore" v-for="(item,index) in dataList" :key="index" class="width100 margin-bottom2">
        <div class="flex flex-center">
          <img class="conditions-box-title-img" :src="item.titleIcon" alt="title">
          <span class="flex1 margin-left2 fontSize16 font-weight letter-spacing1">{{ item.name }}</span>
          <div @click="lookMore(index)" class="cursor-pointer actives05 fontSize14">
            <span>查看更多</span>
            <i v-if="item.isMore" class="el-icon-caret-bottom fontSize18 margin-left1"></i>
            <i v-else class="el-icon-caret-top fontSize18 margin-left1"></i>
          </div>
        </div>
        <div v-if="item.list.length" class="fontSize14 clear-both-after" :class="item.isMore ? 'overflow-hidden' : 'max-height150 overflow-auto'"
             :style="item.isMore ? 'height: 28px' : ''">
          <span v-for="(items,indexs) in item.list" :key="indexs" @click="selected(index,indexs)"
                class="float-left margin-right3 line-height2 cursor-pointer actives05" :class="items.selected ? 'textColor19' : ''">
            {{ items.name }}
          </span>
        </div>
      </div>
      <div v-show="isQueryMore" class="width100 margin-bottom6">
        <div class="flex flex-center">
          <img class="conditions-box-title-img" :src="titleIcon5" alt="title">
          <span class="flex1 margin-left2 fontSize16 font-weight letter-spacing1">其他</span>
        </div>
        <div class="margin-top1 fontSize14 flex flex-center">
          <el-checkbox @change="setKeyword" v-model="queryData.thisCity">
            <span class="textColor10">同省市</span>
          </el-checkbox>
          <el-checkbox @change="setKeyword" v-model="queryData.hasStock">
            <span class="textColor10">有货</span>
          </el-checkbox>
          <el-checkbox @change="setKeyword" v-model="queryData.followStore">
            <span class="textColor10">关注的店铺</span>
          </el-checkbox>
          <div class="flex1"></div>
        </div>
      </div>
      <div class="width100">
        <div class="flex flex-center fontSize14 background-color10 textColor03 border-radius1 padding2 box-sizing">
          <span>为你找到以下店铺：</span>
          <div class="flex1 margin-left4 margin-right4 fontSize12">
            <span v-if="queryData.thisCity">同省市 | </span>
            <span v-if="queryData.hasStock">有货 | </span>
            <span v-if="queryData.followStore">关注的店铺 | </span>
            <span>{{ selectedName }}</span>
          </div>
          <span @click="emptyCondition" class="cursor-pointer actives05">清空条件</span>
          <el-divider direction="vertical"></el-divider>
          <div v-show="isQueryMore" @click="queryMore" class="cursor-pointer actives05">
            <span>收起</span>
            <i class="el-icon-caret-bottom fontSize18 margin-left1"></i>
          </div>
          <div v-show="!isQueryMore" @click="queryMore" class="cursor-pointer actives05">
            <span>更多</span>
            <i class="el-icon-caret-top fontSize18 margin-left1"></i>
          </div>
          <!--<span class="margin-left2 fontSize12 textColor09">
            共{{ totalSize }}家店铺，共{{ totalSize ? totalPage : 0 }}页，{{ totalSize ? queryData.page : 0 }}页
          </span>-->
        </div>
      </div>
    </div>
    <div v-loading="loading" class="min-height500 conditions-box content-box margin-auto margin-bottom6 box-sizing border-radius2 textColor03 min-height300 el-loading-spinners">
      <div v-for="(item,index) in infoList" :key="index" @click="toStoreDetailFn(item)"
           class="width100 box-sizing padding2 border-radius1 box-shadow10 margin-bottom4 flex info-item transition03">
        <div class="height100 flex flex-direction-column">
          <div class="line-height0 position-relative">
            <el-image class="info-item-img border-radius1 overflow-hidden" fit="cover" :src="item.logoImage || defaultImg" alt="logo"/>
            <div v-if="item.followed" class="position-absolute left0 bottom0 width40 line-height2 fontSize12 textColor10 padding-left2 background-color-gradient09">
              已关注
            </div>
          </div>
          <div class="margin-top2 flex width100">
            <div v-if="item.images && item.images.length > 0" class="line-height0">
              <!-- <el-image v-for="(img,indexs) in item.images.slice(0, 5)" :src="img" :key="indexs" :previewSrcList="item.images" @click.stop="" alt="" fit="cover" class="info-item-imgs border-radius1 overflow-hidden cursor-pointer actives05"/> -->
              <el-image v-for="(img,indexs) in item.images.slice(0, 5)" :src="img" :key="indexs" @click="imgClick(indexs,item.images)" @click.stop="" alt="" fit="cover" class="info-item-imgs border-radius1 overflow-hidden cursor-pointer actives05"/>
            </div>
            <!-- <el-image v-else :src="defaultImg" :previewSrcList="[defaultImg]" @click.stop="" alt="" fit="cover" class="info-item-imgs border-radius1 overflow-hidden cursor-pointer actives05"/> -->
            <el-image v-else :src="defaultImg" @click="imgClick(0,[defaultImg])" @click.stop="" alt="" fit="cover" class="info-item-imgs border-radius1 overflow-hidden cursor-pointer actives05"/>
          </div>
        </div>
        <div class="height100 flex1 margin-left3">
          <div class="fontSize16 textColor03 font-weight text-show-one">
            <span class="margin-right2">{{ item.name }}</span>
            <span v-if="item.type" class="fontSize13 font-weight400 padding-left1 padding-right1 border-radius1 textColor24 border4 background-color29 margin-right2">
              {{ renderType(item) }}
            </span>
            <span v-if="item.flagThreesoft" class="fontSize13 font-weight400 padding-left1 padding-right1 border-radius1 textColor23 border3 margin-right2">
                思锐认证
            </span>
            <span v-if="item.flagOptimization" class="fontSize13 font-weight400 padding-left1 padding-right1 border-radius1 textColor23 border3 margin-right2">
                优质商家
            </span>
            <span v-if="item.flagPower" class="fontSize13 font-weight400 padding-left1 padding-right1 border-radius1 textColor23 border3">
                实力商家
            </span>
          </div>
          <div v-if="item.categories" class="fontSize14 textColor03 font-weight400 margin-top1 text-show-one">
            {{ renderCategories(item) }}
          </div>
          <div class="fontSize12 textColor06 font-weight400 margin-top1">
            <span>{{ item.areas ? item.areas[1] : "" }}</span>
          </div>
          <div v-if="item.autoPartsCity" class="fontSize12 textColor23 font-weight400 margin-top1">
            <i class="iconfont icon-fill_home m-r-4 font-size-12"></i>
            <span class="textColor23 margin-left1">{{ item.autoPartsCity }}</span>
          </div>
          <div v-if="item.description" class="fontSize12 textColor06 font-weight400 margin-top1 line-height2 text-show-three">
            <span>{{ item.description }}</span>
          </div>
          <div v-if="item.counts" class="fontSize12 textColor09 font-weight400 margin-top1">
            <span class="margin-right2">{{ item.counts.follows || "0" }}人关注</span>
            <span class="margin-right2">{{ item.counts.browses || "0" }}人浏览</span>
            <span class="margin-right2">{{ item.counts.consultations || "0" }}人咨询</span>
            <span class="margin-right2">{{ item.counts.parts || "0" }}件商品</span>
          </div>
        </div>
        <div class="height100 margin-left3">
          <div class="flex flex-center fontSize14 textColor03">
            <div v-if="!item.followed" @click.stop="collect(item)" class="actives05 cursor-pointer flex flex-center">
              <svg t="1667186659379" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1093" width="16" height="16">
                <path
                  d="M908.8 214.4c-9.6-12.8-19.2-22.4-28.8-32-112-115.2-230.4-105.6-342.4-16-9.6 6.4-19.2 16-28.8 25.6-9.6-9.6-19.2-16-28.8-25.6-112-86.4-230.4-99.2-342.4 16-9.6 9.6-19.2 19.2-25.6 32-134.4 195.2-60.8 387.2 137.6 560 44.8 38.4 89.6 73.6 137.6 102.4 16 9.6 32 19.2 44.8 28.8 9.6 6.4 12.8 9.6 19.2 9.6 3.2 3.2 6.4 3.2 12.8 6.4 3.2 3.2 9.6 3.2 16 6.4 25.6 6.4 64 3.2 89.6-12.8 3.2 0 9.6-3.2 16-9.6 12.8-6.4 28.8-16 44.8-28.8 48-28.8 92.8-64 137.6-102.4C969.6 598.4 1043.2 406.4 908.8 214.4zM736 732.8c-41.6 35.2-86.4 70.4-131.2 99.2-16 9.6-28.8 19.2-44.8 25.6-6.4 3.2-12.8 6.4-16 9.6-6.4 3.2-16 6.4-25.6 9.6-3.2 0-6.4 0-9.6 0-6.4 0-12.8 0-16 0-3.2 0-3.2 0-3.2 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0-3.2 0-3.2-3.2-3.2 0-6.4-3.2-9.6-3.2-3.2-3.2-9.6-6.4-16-9.6-12.8-6.4-28.8-16-44.8-25.6-44.8-28.8-89.6-60.8-131.2-99.2-179.2-160-243.2-323.2-131.2-489.6 6.4-9.6 16-16 22.4-25.6 89.6-96 182.4-86.4 275.2-12.8 9.6 6.4 16 12.8 22.4 19.2 0 0 0 0 0 0l28.8 32c3.2 3.2 3.2 3.2 6.4 6.4 0 0 0 0 0 0l0 0c3.2-3.2 9.6-9.6 16-16 12.8-12.8 25.6-25.6 41.6-38.4 92.8-73.6 185.6-83.2 275.2 12.8 6.4 9.6 16 16 22.4 25.6C982.4 406.4 918.4 572.8 736 732.8z"
                  fill="#8a8a8a" p-id="1094"></path>
              </svg>
              <span class="margin-left2">关注</span>
            </div>
            <div v-else>
              已关注
            </div>
          </div>
          <div class="flex flex-center fontSize14 textColor03 margin-top2">
            <div @click.stop="copyLink(item)" class="actives05 cursor-pointer flex flex-center">
              <svg t="1667186930963" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1401" width="16" height="16">
                <path
                  d="M558.933333 699.733333l-170.666666-93.866666c-25.6 21.333333-55.466667 34.133333-89.6 34.133333-72.533333 0-128-55.466667-128-128s55.466667-128 128-128c38.4 0 68.266667 17.066667 93.866666 38.4L597.333333 320V298.666667c0-72.533333 55.466667-128 128-128s128 55.466667 128 128-55.466667 128-128 128c-34.133333 0-64-12.8-85.333333-34.133334l-213.333333 110.933334v21.333333l174.933333 98.133333c21.333333-17.066667 51.2-29.866667 81.066667-29.866666 72.533333 0 128 55.466667 128 128s-55.466667 128-128 128-128-55.466667-128-128c0-4.266667 0-12.8 4.266666-21.333334zM298.666667 554.666667c25.6 0 42.666667-17.066667 42.666666-42.666667s-17.066667-42.666667-42.666666-42.666667-42.666667 17.066667-42.666667 42.666667 17.066667 42.666667 42.666667 42.666667z m426.666666-213.333334c25.6 0 42.666667-17.066667 42.666667-42.666666s-17.066667-42.666667-42.666667-42.666667-42.666667 17.066667-42.666666 42.666667 17.066667 42.666667 42.666666 42.666666z m-42.666666 426.666667c25.6 0 42.666667-17.066667 42.666666-42.666667s-17.066667-42.666667-42.666666-42.666666-42.666667 17.066667-42.666667 42.666666 17.066667 42.666667 42.666667 42.666667z"
                  fill="#F3D146" p-id="1402"></path>
              </svg>
              <span class="margin-left2">分享</span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-center">
        <div class="flex1"></div>
        <el-pagination
          background
          layout="prev, next"
          :page-size="queryData.page"
          :total="totalPage"
          @current-change="pageChange"
        >
        </el-pagination>
      </div>
    </div>
    <fixedNav v-if="!isImportTP"/>
  </div>
</template>
<script>
import {historyLogsListApi} from "@/api/home/log";
import {getDict, getBrands, getVehBrands, getCategories} from "@/api/basic";
import {addFocusStoreApi} from '@/api/focus';
import {postCompanyList} from "@/api/home/company";
import {createHistoryLogsApi} from "@/api/home/log";
import titleIcon1 from "@/assets/home/yellowPage1.png"
import titleIcon2 from "@/assets/home/yellowPage2.png"
import titleIcon3 from "@/assets/home/yellowPage3.png"
import titleIcon4 from "@/assets/home/yellowPage4.png"
import titleIcon5 from "@/assets/home/yellowPage5.png"
import defaultImg from "@/assets/maintenance/default-store.png"
import fixedNav from "@/components/fixedNav/fixedNav";
import eventBus from "@/event";

export default {
  components: {
    fixedNav,
  },
  props: {
    isImportTP: {
      type: Boolean,
      default: false
    },
    keywordValue: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      titleIcon5,
      defaultImg,
      keyword: "",
      searchHistory: [],
      dataList: [
        {
          name: "主营配件品牌",
          isMore: true,
          titleIcon: titleIcon1,
          selected: [],
          list: []
        },
        {
          name: "主营车型品牌",
          isMore: true,
          titleIcon: titleIcon2,
          selected: [],
          list: []
        },
        {
          name: "主营配件类别",
          isMore: true,
          titleIcon: titleIcon3,
          selected: [],
          list: []
        },
        {
          name: "汽配城",
          isMore: true,
          titleIcon: titleIcon4,
          selected: [],
          list: []
        }
      ],
      queryData: {
        page: 1,
        pageSize: 20,
        keyword: "",
        thisProvince: false,
        brands: [],
        vehBrands: [],
        categories: [],
        autoPartsCity: [],
        type: ["dealer"],
        thisCity: false,
        hasStock: true,
        followStore: false
      },
      selectedList: [],
      selectedName: "",
      infoList: [],
      totalPage: 0,
      totalSize: 0,
      loading: false,
      queryType: 2,
      isQueryMore: false
    };
  },
  watch: {
    keywordValue: {
      handler: function (val) {
        this.keyword = val;
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.searchInput.focus();
    });
    if (this.keywordValue) {
      this.keyword = this.keywordValue;
    }
    this.initData();
  },
  methods: {
        imgClick(index,images){
          eventBus.$emit("ShowImageViewer", {
          index: index,
          list: images
        });
    },
    initData() {
      this.setQueryData();
      this.getSearchHistory();
      this.getBrands();
      this.getVehBrands();
      this.getCategories();
      this.getDict();
    },
    // 获取搜索历史
    getSearchHistory() {
      historyLogsListApi({
        type: "searchStore",
      }).then((res) => {
        res = this.$uniqueObject(res || [], "keyword");
        this.searchHistory = res || [];
      });
    },
    // 获取主营配件品牌
    getBrands() {
      getBrands({}).then((res) => {
        if (!res) {
          this.dataList[0].list = [];
        }
        for (let item of res) {
          item.selected = false;
        }
        this.dataList[0].list = res;
      });
    },
    // 获取主营车型品牌
    getVehBrands() {
      getVehBrands({}).then((res) => {
        if (!res) {
          this.dataList[1].list = [];
        }
        for (let item of res) {
          item.selected = false;
        }
        this.dataList[1].list = res;
      });
    },
    // 获取主营配件类别
    getCategories() {
      getCategories({}).then((res) => {
        if (!res) {
          this.dataList[2].list = [];
        }
        for (let item of res) {
          item.selected = false;
        }
        this.dataList[2].list = res;
      });
    },
    // 获取汽配城
    getDict() {
      getDict({type: "autoPartsCity"}).then((res) => {
        if (!res) {
          this.dataList[3].list = [];
        }
        for (let item of res) {
          item.selected = false;
        }
        this.dataList[3].list = res;
      });
    },
    getInfoList() {
      this.loading = true;
      postCompanyList(this.queryData).then((res) => {
        if (res.rows) {
          this.infoList = res.rows || [];
        }
        this.totalPage = res.totalPage || 1;
        this.totalSize = res.totalSize || 0;

        this.getSearchHistory();
      }).finally(() => {
        this.loading = false;
      });
    },
    // 搜索历史
    createHistoryLogsApi() {
      if (this.queryData.keyword) {
        createHistoryLogsApi({
          type: "searchStore",
          content: this.queryData,
        });
      }
    },
    // 设置查询条件
    setQueryData(query) {
      this.queryData = {
        ...this.queryData,
        keyword: this.keyword,
        page: 1,
        thisProvince: this.queryData.thisCity,
        brands: this.dataList[0].selected,
        vehBrands: this.dataList[1].selected,
        categories: this.dataList[2].selected,
        autoPartsCity: this.dataList[3].selected,
        type: ["dealer"],
        ...query
      }
      this.createHistoryLogsApi();
      this.getInfoList();
    },
    // 条首页
    redirect(url) {
      this.$router.push(url);
    },
    // 选择历史
    chooseTag(keyword) {
      this.keyword = keyword;
      this.setQueryData({});
    },
    // 搜索
    setKeyword() {
      this.isQueryMore = false;
      this.setQueryData({});
    },
    // 分类查看更多
    lookMore(index) {
      this.dataList[index].isMore = !this.dataList[index].isMore;
    },
    // 分类选择
    selected(index, indexs) {
      this.dataList[index].list[indexs].selected = !this.dataList[index].list[indexs].selected;
      this.getSelected();
    },
    // 获取选择数据
    getSelected() {
      let arr = [];
      let selectedName = [];
      this.dataList = this.dataList.map((item) => {
        item.selected = [];
        item.list.map((items) => {
          items.selected && arr.push(items) && selectedName.push(items.name) && item.selected.push(items.name);
        })
        return item;
      })
      this.selectedName = selectedName.join(" | ");
      this.selectedList = arr;
      this.setQueryData({});
    },
    // 清空条件
    emptyCondition() {
      this.selectedName = "";
      this.selectedList = [];
      this.dataList = this.dataList.map((item) => {
        item.list.map((items) => {
          items.selected = false;
          return items;
        })
        item.selected = [];
        return item;
      })
      this.queryData = {
        page: 1,
        pageSize: 20,
        keyword: "",
        thisProvince: false,
        brands: [],
        vehBrands: [],
        categories: [],
        autoPartsCity: [],
        type: ["dealer"],
        thisCity: false,
        hasStock: false,
        followStore: false
      };
      this.setQueryData({});
    },
    // 商户类型
    renderType(item) {
      if (item.type === "dealer") {
        return "经销商";
      }
      if (item.type === "garage") {
        return "汽修厂";
      }
      if (item.type === "manufacturer") {
        return "生产厂家";
      }
    },
    // 商户类别
    renderCategories(item) {
      let arr = [];
      if (item.categories && item.categories.length > 0) {
        for (let a of item.categories) {
          arr.push(a.join(">"));
        }
        return arr.join(" | ");
      } else {
        return "";
      }
    },
    // 关注
    collect(item) {
      if (!this.$store.state.profile.id) {
        this.$router.push('/login');
        return;
      }
      addFocusStoreApi({
        id: item.id
      }).then(() => {
        this.$message.success('关注成功');
        item.followed = true;
      })
    },
    // 分享
    copyLink(item) {
      const link = `${location.origin}/home/store?storeId=${item.id}&tabsActive=storeInfo`
      const _this = this
      this.$copyText(link).then(
        function () {
          _this.$message({
            message: "已复制到剪贴板",
            type: "success",
          });
        },
        function () {
          _this.$message({
            message: "复制失败",
            type: "error",
          });
        }
      );
    },
    // 跳转详情
    toStoreDetailFn(item) {
      let obj = {};
      if (item.counts && item.counts.parts !== "0") {
        //是否已商家配件
        obj = {
          path: "/home/store",
          query: {
            storeId: item.id,
          },
        };
      } else {
        obj = {
          path: "/home/store",
          query: {
            storeId: item.id,
            tabsActive: "storeInfo",
          },
        };
      }
      // this.$router.push(obj);
      // 跳转商家，新开tab页
      const routeData = this.$router.resolve(obj);
      window.open(routeData.href, '_blank');
    },
    // 找货/找商家
    handleChangeQueryType(e) {
      if (Number(e) === 1) {
        this.$emit("switchTP", true)
      }
    },
    // 分页
    pageChange(e) {
      if (this.isQueryMore) {
        this.$APPScrollTop(400);
      } else {
        this.$APPScrollTop(200);
      }
      this.queryData.page = e;
      this.getInfoList();
    },
    queryMore() {
      this.isQueryMore = !this.isQueryMore;
    }
  },
};
</script>
<style lang="less" scoped>
.page-yellow .logo-img {
  height: 64px;
}

.page-yellow .search-img {
  width: 20px;
  height: 20px;
  margin-top: 2px;
}

.page-yellow .search-box {
  border: 3px solid #FF6E4C;
}

.page-yellow .search-box-input {
  border: none;
  outline: none;
}

.page-yellow .conditions-box-title-img {
  width: 16px;
  height: 16px;
}

/deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #FF6E4C !important;
  border: #FF6E4C !important;
}

.info-item {
  border: 1px solid #ffffff;
}

.info-item:hover {
  background-color: #ffffff;
  border: 1px solid #FF6E4C;
}

.info-item-img {
  width: 226px;
  height: 140px;
}

.info-item-imgs {
  width: 40px;
  height: 40px;
  margin-right: 6px;
}
</style>